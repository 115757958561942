<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-13 20:50:49
 * @LastEditTime: 2020-04-30 17:49:34
 -->
<template>
  <div v-loading="loading">
    <el-form inline :model="searchForm" class="searchForm" size="small">
      <el-form-item label="性别">
        <el-select
          placeholder="请选择性别"
          style="width: 160px;"
          filterable
          clearable
          size="small"
          v-model="searchForm.sex"
        >
          <el-option
            v-for="item in dictData.sexList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="来源渠道">
        <el-select
          placeholder="请选择渠道"
          size="small"
          style="width: 160px;"
          filterable
          clearable
          v-model="searchForm.sources"
        >
          <el-option
            v-for="item in dictData.sorceList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="应聘岗位">
        <el-select
          placeholder="请选择岗位"
          style="width: 160px;"
          filterable
          size="small"
          clearable
          v-model="searchForm.jobId"
        >
          <el-option
            v-for="item in jobList"
            :key="item.id"
            :label="item.jobOrOfficeName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工作年限">
        <el-select
          placeholder="请选择工作年限"
          style="width: 160px;"
          size="small"
          filterable
          clearable
          v-model="searchForm.yearsWorking"
        >
          <el-option
            v-for="item in dictData.experiencelist"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最高学历">
        <el-select
          placeholder="请选择最高学历"
          style="width: 160px;"
          filterable
          size="small"
          clearable
          v-model="searchForm.highestEducation"
        >
          <el-option
            v-for="item in dictData.educationList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="移动原因">
        <el-select
          v-model="searchForm.reasonId"
          placeholder="请选择渠道"
          style="width: 160px;"
          filterable
          size="small"
          clearable
        >
          <el-option
            v-for="item in classfyList[1] && classfyList[1].list"
            :key="item.id"
            :label="item.tagName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
            <el-form-item label="投递时间">
        <el-date-picker
          v-model="searchForm.deliveryTimeBefore"
          size="small"
          default-time="00:00:00"
          type="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd"
          clearable
          @clear="_clear"
        ></el-date-picker>
        <span style="margin:0 5px">-</span>
        <el-date-picker
          v-model="searchForm.deliveryTimeEnd"
          size="small"
          default-time="23:59:59"
          type="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd"
          clearable
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="searchForm.createTimeBefore"
          size="small"
          default-time="'00:00:00"
          type="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd"
          clearable
          @clear="_clear2"
        ></el-date-picker>
        <span style="margin:0 5px">-</span>
        <el-date-picker
          v-model="searchForm.createTimeEnd"
          size="small"
          default-time="23:59:59"
          type="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd"
          clearable
          @clear="_clear2"
        ></el-date-picker>
      </el-form-item>
      <el-button type="primary" size="small" style="margin-left:5px;" icon="el-icon-search" @click="_getList(1)">查询</el-button>
    </el-form>
    <el-table :data="list" stripe>
      <el-table-column type="index" label="序号" :width="50" fixed></el-table-column>
      <el-table-column label="基础信息" align="left" show-overflow-tooltip width="300">
        <template slot-scope="{row}">
          <p
            class="moryeBasic"
          >{{row.name}} <i :style="{color : row.sex=='1' ? '#498DF0' : '#FF7371'}">{{row.sex=='1'?'♂':'♀'}}</i> {{row.yearsWorking}}·{{row.highestEducationName}}</p>
          <p class="moryeBasic">{{row.phoneNumber}}/{{row.mailbox}}</p>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(col,i) in columns"
        :prop="col.prop"
        :key="i"
        align="center"
        :label="col.label"
        width="200"
        show-overflow-tooltip
        :sortable="col.prop == 'deliveryTime' || col.prop == 'createTime'"
      >
        <template v-slot="{ row }" v-if="col.prop == 'deliveryTime' || col.prop == 'createTime'">
          {{row[col.prop].slice(0,10)}}
        </template>
        <template
          v-slot="{ row }"
          v-else-if="col.prop == 'graduatedSchool'"
        >{{ row.graduatedSchool }}/{{ row.industry }}</template>
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right">
        <template slot-scope="scope">
          <el-dropdown>
            <el-button type="text"><img src="../../../../assets/button/操作图标@3x.png" width="16px" /></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, i) in commands"
                @click.native.stop="_handleRowClick(scope.row, item)"
                :command="item"
                :key="i"
              >{{ item.label }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin:20px 0;text-align: right;"
      @size-change="_handleSizeChange"
      @current-change="_handleCurrentChange"
      :current-page="searchForm.pageNo"
      :page-sizes="[20, 50, 100, 200]"
      :page-size="searchForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="searchForm.total"
    ></el-pagination>
  </div>
</template>
<script>
import { getListReserve } from '@/api/recruitment_allocation.js';
export default {
  props: ['jobArr', 'classifyListNew'],
  data() {
    return {
      dateRange: '',
      dateRange2: '',
      loading: false,
      searchForm: {
        sex: '',
        sources: '',
        yearsWorking: '',
        labelId: '',
        entryTimeBefore: '',
        entryTimeEnd: '',
        jobId: '',
        highestEducation: '',
        reasonId: '',
        createTimeBefore: '',
        createTimeEnd: '',
        deliveryTimeSort: '',
        moveTimeSort: '',
        pageNo: 1,
        pageSize: 20,
        total: 0
      },
      list: [],
      jobList: this.jobArr,
      classfyList: this.classifyListNew,
      commands: [
        {
          label: '安排面试',
          value: '1'
        },
        {
          label: '打印',
          value: '2'
        },
        {
          label: '移动',
          value: '3'
        },
        {
          label: '简历详情',
          value: '4'
        }
      ],
      commands2: [
        {
          label: '审批再入职',
          value: '1'
        },
        {
          label: '打印',
          value: '2'
        },
        {
          label: '移动',
          value: '3'
        },
        {
          label: '简历详情',
          value: '4'
        }
      ],
      columns: [
        { label: '毕业院校/专业', prop: 'graduatedSchool' },
        { label: '应聘岗位', prop: 'jobName' },
        { label: '投递渠道', prop: 'sources' },
        { label: '原因', prop: 'reason' },
        { label: '投递时间', prop: 'deliveryTime' },
        { label: '创建时间', prop: 'createTime' }
      ]
    };
  },
  methods: {
    _handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this._getList();
    },
    _handleCurrentChange(val) {
      this.searchForm.pageNo = val;
      this._getList();
    },
    _getList(page) {
      page ? this.searchForm.pageNo = page : '';
      this.loading = true;
      getListReserve(this.searchForm).then((res) => {
        this.loading = false;
        this.list = res.list;
        this.searchForm.total = res.totalNum;
      });
    },
    _handleRowClick(row, item) {
      switch (item.label) {
        case '安排面试':
          this.$router.push({name: 'ScheduleInterview', params: {resumeId: row.id}});
          break;
        case '移动':
          this.$emit('showDialog', {detail: row, title: '移动简历'});
          break;
        case '打印':
          this.$router.push({ name: 'RecruitPrint', params: { id: row.id } });
          break;
        case '审批再入职':
          this.$router.push({name: 'AdmissionInfo', query: { resumeid: row.id }});
          break;
        default:
          this.$router.push({name: 'ResumeInfo', params: {id: row.id}});
          break;
      }
    },
    _entryApplyOpen(row) {
      if (row.entryApplyOpen == 0 && row.entryApplyStatus) {
        return true;
      }
        return false;

    },
    _dateRangeChange(val) {
      if (val) {
        this.searchForm.entryTimeBefore = val[0];
        this.searchForm.entryTimeEnd = val[1];
      } else {
        this._clear();
      }
    },
    _clear() {
      this.dateRange = '';
      this.searchForm.entryTimeBefore = '';
      this.searchForm.entryTimeEnd = '';
    },
    _dateRangeChange2(val) {
      if (val) {
        this.searchForm.createTimeBefore = val[0];
        this.searchForm.createTimeEnd = val[1];
      } else {
        this._clear2();
      }
    },
    _clear2() {
      this.dateRange2 = '';
      this.searchForm.createTimeBefore = '';
      this.searchForm.createTimeEnd = '';
    }
  },
  mounted() {
    this._getList();
  },
  computed: {
    dict() {
      return this.$store.state.app.dict;
    },
    dictData() {
      const Arr = {
        sexList: [],
        sorceList: [],
        experiencelist: [],
        educationList: []
      };

      Array.isArray(this.dict) &&
        this.dict.forEach((item) => {
          if (item.groupId === 'sex') {
            Arr.sexList.push({ value: item.dictId, label: item.dictName });
          }
          if (item.groupId === 'fromType') {
            Arr.sorceList.push({ value: item.dictId, label: item.dictName });
          }
          if (item.groupId === 'experience') {
            Arr.experiencelist.push({
              value: item.dictId,
              label: item.dictName
            });
          }
          if (item.groupId === 'highestEducation') {
            Arr.educationList.push({
              value: item.dictId,
              label: item.dictName
            });
          }
        });
      return Arr;
    }
  },
  watch: {
    jobArr: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.jobList = newVal;
      }
    },
    classifyListNew: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.classfyList = newVal;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.moryeBasic{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
