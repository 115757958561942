<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-13 20:52:40
 * @LastEditTime: 2020-04-30 09:40:33
 -->
<template>
  <div v-loading="loading">
    <el-form inline :model="searchForm" class="searchForm" size="small">
      <el-form-item label="性别">
        <el-select style="width: 160px;" placeholder="请选择性别" filterable clearable size="small" v-model="searchForm.sex">
          <el-option
            v-for="item in dictData.sexList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="来源渠道">
        <el-select
          placeholder="请选择渠道"
          size="small"
          filterable
          clearable
          style="width: 160px;"
          v-model="searchForm.sources"
        >
          <el-option
            v-for="item in dictData.sorceList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="应聘岗位">
        <el-select style="width: 160px;" placeholder="请选择岗位" filterable size="small" clearable v-model="searchForm.jobId">
          <el-option
            v-for="item in jobList"
            :key="item.id"
            :label="item.jobOrOfficeName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工作年限">
        <el-select
          placeholder="请选择工作年限"
          size="small"
          filterable
          clearable
          v-model="searchForm.yearsWorking"
          style="width: 160px;"
        >
          <el-option
            v-for="item in dictData.experiencelist"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最高学历">
        <el-select
          placeholder="请选择最高学历"
          filterable
          size="small"
          clearable
          v-model="searchForm.highestEducation"
          style="width: 160px;"
        >
          <el-option
            v-for="item in dictData.educationList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
            <el-form-item label="投递时间">
        <el-date-picker
          v-model="searchForm.deliveryTimeBefore"
          size="small"
          default-time="00:00:00"
          type="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd"
          clearable
          @clear="_clear"
        ></el-date-picker>
        <span style="margin:0 5px">-</span>
        <el-date-picker
          v-model="searchForm.deliveryTimeEnd"
          size="small"
          default-time="23:59:59"
          type="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd"
          clearable
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="searchForm.createTimeBefore"
          size="small"
          default-time="'00:00:00"
          type="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd"
          clearable
          @clear="_clear2"
        ></el-date-picker>
        <span style="margin:0 5px">-</span>
        <el-date-picker
          v-model="searchForm.createTimeEnd"
          size="small"
          default-time="23:59:59"
          type="date"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd"
          clearable
          @clear="_clear2"
        ></el-date-picker>
      </el-form-item>
      <el-button type="primary" size="small" @click="_getList(1)" icon="el-icon-search" style="margin-left:5px">查询</el-button>
      <el-button type="primary" size="small" @click="_exportPage()" icon="el-icon-download" style="float:right">导出</el-button>
    </el-form>
    <el-table :data="list" stripe @selection-change="_handleSelectionChange" @row-click="_rowClick">
      <el-table-column type="selection" width="55" fixed></el-table-column>
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column label="基础信息" align="left" show-overflow-tooltip width="300">
        <template slot-scope="{row}">
          <p
            class="moryeBasic"
          >{{row.name}} <i :style="{color : row.sex=='1' ? '#498DF0' : '#FF7371'}">{{row.sex=='1'?'♂':'♀'}}</i> {{row.yearsWorking}}·{{row.highestEducationName}}</p>
          <p class="moryeBasic">{{row.phoneNumber}}/{{row.mailbox}}</p>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(col,i) in columns"
        :prop="col.prop"
        :key="i"
        align="center"
        :label="col.label"
        width="200"
        show-overflow-tooltip
        :sortable="col.prop == 'deliveryTime' || col.prop == 'createTime'"
      >
        <template
          v-slot="{ row }"
          v-if="col.prop == 'deliveryTime' || col.prop == 'createTime'"
        >{{row[col.prop].slice(0,10)}}</template>
        <template
          v-slot="{ row }"
          v-else-if="col.prop == 'graduatedSchool'"
        >{{ row.graduatedSchool }}/{{ row.industry }}</template>
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right">
        <template slot-scope="scope">
          <el-link type="primary" :underline="false" @click="_export(scope.row)">导出</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin : 20px 0;text-align: right;"
      @size-change="_handleSizeChange"
      @current-change="_handleCurrentChange"
      :current-page="searchForm.pageNo"
      :page-sizes="[20, 50, 100, 200]"
      :page-size="searchForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="searchForm.total"
    ></el-pagination>
  </div>
</template>
<script>
import { recruitEntryList } from '@/api/recruitment_allocation.js';
export default {
  props: ['jobArr'],
  data() {
    return {
      dateRange: '',
      dateRange2: '',
      loading: false,
      searchForm: {
        sex: '',
        sources: '',
        yearsWorking: '',
        labelId: '',
        entryTimeBefore: '',
        entryTimeEnd: '',
        jobId: '',
        highestEducation: '',
        reasonId: '',
        createTimeBefore: '',
        createTimeEnd: '',
        deliveryTimeSort: '',
        moveTimeSort: '',
        pageNo: 1,
        pageSize: 20,
        total: 0
      },
      list: [],
      multipleSelection: [],
      jobList: this.jobArr,
      columns: [
        { label: '毕业院校/专业', prop: 'graduatedSchool' },
        { label: '应聘岗位', prop: 'jobName' },
        { label: '投递渠道', prop: 'sources' },
        { label: '入职时间', prop: 'enrollEntryTime' },
        { label: '创建时间', prop: 'createTime' }
      ]
    };
  },
  methods: {
    _handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this._getList();
    },
    _handleCurrentChange(val) {
      this.searchForm.pageNo = val;
      this._getList();
    },
    _getList(page) {
      page ? (this.searchForm.pageNo = page) : '';
      this.loading = true;
      recruitEntryList(this.searchForm).then((res) => {
        this.loading = false;
        this.multipleSelection = [];
        this.list = res.list;
        this.searchForm.total = res.totalNum;
      });
    },
    _handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    _export(row) {
      this._exportPage(row.id);
    },
    _exportPage(id) {
      let _url = '/v1/recruit/resume/list/export?params=';

      if (id) {
        const _obj = {
          ids: [id]
        };

        _url += encodeURIComponent(JSON.stringify(_obj));
      } else {
        if (this.multipleSelection.length <= 0) {
          this.$message.error('请先选择记录');
          return false;
        }
        const _list = [];

        this.multipleSelection.forEach((item) => {
          _list.push(item.id);
        });
        const _obj = {
          ids: _list
        };

        _url += encodeURIComponent(JSON.stringify(_obj));
      }
      window.open(document.location.origin + _url);
    },
    _rowClick(row) {
      this.$router.push({name: 'ResumeInfo', params: {id: row.id}});
    },
    _dateRangeChange(val) {
      if (val) {
        this.searchForm.entryTimeBefore = val[0];
        this.searchForm.entryTimeEnd = val[1];
      } else {
        this._clear();
      }
    },
    _clear() {
      this.dateRange = '';
      this.searchForm.entryTimeBefore = '';
      this.searchForm.entryTimeEnd = '';
    },
    _dateRangeChange2(val) {
      if (val) {
        this.searchForm.createTimeBefore = val[0];
        this.searchForm.createTimeEnd = val[1];
      } else {
        this._clear2();
      }
    },
    _clear2() {
      this.dateRange2 = '';
      this.searchForm.createTimeBefore = '';
      this.searchForm.createTimeEnd = '';
    }
  },
  mounted() {
    this._getList();
  },
  computed: {
    dict() {
      return this.$store.state.app.dict;
    },
    dictData() {
      const Arr = {
        sexList: [],
        sorceList: [],
        experiencelist: [],
        educationList: []
      };

      Array.isArray(this.dict) &&
        this.dict.forEach((item) => {
          if (item.groupId === 'sex') {
            Arr.sexList.push({ value: item.dictId, label: item.dictName });
          }
          if (item.groupId === 'fromType') {
            Arr.sorceList.push({ value: item.dictId, label: item.dictName });
          }
          if (item.groupId === 'experience') {
            Arr.experiencelist.push({
              value: item.dictId,
              label: item.dictName
            });
          }
          if (item.groupId === 'highestEducation') {
            Arr.educationList.push({
              value: item.dictId,
              label: item.dictName
            });
          }
        });
      return Arr;
    }
  },
  watch: {
    jobArr: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.jobList = newVal;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.moryeBasic {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
