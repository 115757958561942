<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-11 14:32:55
 * @LastEditTime: 2020-04-22 14:50:31
 -->
<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane
				:label="`${classifyList[0] && classifyList[0].classifyName}(${_returnNumber(10)})`"
				name="0"
				v-if="permissionControl('/v1/recruit/statistics/hiring/funnel')"
				lazy
			>
				<ObsoleteCom ref="child0" :jobArr="jobList" :classifyListNew="classifyList" @showDialog="_getDetail"></ObsoleteCom>
			</el-tab-pane>
			<el-tab-pane
				:label="`${classifyList[1] && classifyList[1].classifyName}(${_returnNumber(11)})`"
				name="1"
				v-if="permissionControl('/v1/recruit/resume/list/reserve')"
				lazy
			>
				<ReserveCom ref="child1" :jobArr="jobList" :classifyListNew="classifyList" @showDialog="_getDetail"></ReserveCom>
			</el-tab-pane>
			<el-tab-pane
				:label="`${classifyList[2] && classifyList[2].classifyName}(${_returnNumber(12)})`"
				v-if="permissionControl('/v1/recruit/resume/list/black')"
				name="2"
				lazy
			>
				<BlackCom ref="child2" :jobArr="jobList" :classifyListNew="classifyList" @showDialog="_getDetail"></BlackCom>
			</el-tab-pane>
			<!-- <el-tab-pane :label="`删除(${_returnNumber(14)})`" name="3" lazy>
        <DeleteCom
          ref="child3"
          :jobArr="jobList"
          :classifyListNew="classifyList"
          @showDialog="_getDetail"
        ></DeleteCom>
      </el-tab-pane> -->
			<el-tab-pane :label="`已入职(${_returnNumber(6)})`" name="4" lazy v-if="permissionControl('/v1/recruit/entry/list')">
				<HiredCom ref="child4" :jobArr="jobList" :classifyListNew="classifyList" @showDialog="_getDetail"></HiredCom>
			</el-tab-pane>
			<!-- <el-tab-pane :label="`离职(${_returnNumber(13)})`" name="5" lazy>
        <DimissionCom
          ref="child5"
          :jobArr="jobList"
          :classifyListNew="classifyList"
          @showDialog="_getDetail"
        ></DimissionCom>
      </el-tab-pane> -->
		</el-tabs>
		<MoveDialog
			v-if="dialogStatus"
			:currentRow="detail"
			:visible.sync="dialogStatus"
			:title="titles"
			:classifyListNew="classifyList"
			@updateList="_updateList"
		></MoveDialog>
	</div>
</template>
<script>
import ObsoleteCom from './page/obsolete';
// import DeleteCom from "./page/delete";
import BlackCom from './page/black';
// import DimissionCom from "./page/dimission";
import HiredCom from './page/hired';
import ReserveCom from './page/reserve';
import MoveDialog from './components/moveDialog';
import { getPositions } from '@/api/common.js';
import { classifyList, listNum } from '@/api/recruitment_allocation.js';
export default {
	components: {
		ObsoleteCom,
		// DeleteCom,
		BlackCom,
		// DimissionCom,
		HiredCom,
		ReserveCom,
		MoveDialog
	},
	data() {
		return {
			activeName: '0',
			jobList: [],
			classifyList: [],
			dialogStatus: false,
			detail: {},
			titles: ''
		};
	},
	mounted() {
		this._getJobList();
		this._getNum();
		this._getClassifyList();
		const data = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		if (data) {
			this.activeName = data.activeName;
		}
	},
	methods: {
		_getJobList() {
			getPositions({ type: 2, pageNo: 1, pageSize: 20000 }).then((res) => {
				this.jobList = res.list;
			});
		},
		_getClassifyList() {
			classifyList({}).then((res) => {
				this.classifyList = res;
			});
		},
		_getDetail(obj) {
			this.detail = obj.detail;
			this.titles = obj.title;
			this.dialogStatus = true;
		},
		_getNum() {
			listNum({}).then((res) => {
				this.numList = res;
				this.$forceUpdate();
			});
		},
		_returnNumber(status) {
			if (this.numList && this.numList.length >= 1) {
				for (const key in this.numList) {
					if (this.numList[key].status == status) {
						return this.numList[key].count;
					}
				}
				return 0;
			}
			return 0;
		},
		_updateList() {
			this._getNum();
			this.$refs[`child${this.activeName}`] && this.$refs[`child${this.activeName}`]._getList();
		}
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				activeName: this.activeName
			})
		);
		next();
	},
	watch: {
		activeName(newVal) {
			this.$refs[`child${newVal}`] && this.$refs[`child${newVal}`]._getList();
		}
	}
};
</script>
<style scoped lang="scss">
::v-deep.el-form--inline .el-form-item {
	vertical-align: initial;
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
